/* =====================================================================
// App
// ===================================================================== */
export const APP_NAME = "chuchipirat";
export const APP_CLAIM = "einfach kochen für Gruppen";
/* =====================================================================
// Globale Begriffe
// ===================================================================== */
export const RECIPE_OPEN = "Rezept öffnen";
export const VISIBILITY = "Sichtbarkeit";
/* =====================================================================
// Button
// ===================================================================== */
export const BUTTON_OK = "Ok";
export const BUTTON_SAVE = "Speichern";
export const BUTTON_EDIT = "Bearbeiten";
export const BUTTON_CANCEL = "Abbrechen";
export const BUTTON_SHOW = "Anzeigen";
export const BUTTON_ADD = "Hinzufügen";
export const BUTTON_ADD_COMMENT = "Kommentar Hinzufügen";
export const BUTTON_CHANGE = "Ändern";
export const BUTTON_DELETE = "Löschen";
export const BUTTON_SCALE = "Skalieren";
export const BUTTON_OWN_VARIANT = "Eigene Variante erstellen";
export const ADD_DEPARTMENT = "Abteilung hinzufügen";
export const BUTTON_ADD_PERSON = "Person hinzufügen";
export const BUTTON_ADD_TO_EVENT = "Zu Anlass hinzufügen";
export const BUTTON_UPLOAD = "Upload";
export const BUTTON_EVENT_CREATE = "Neuer Anlass anlegen";
export const BUTTON_MY_EVENTS = "Meine Anlässe";
export const BUTTON_SIGN_IN = "";
export const SIGN_IN = "Anmelden";
export const BUTTON_SIGN_UP = "";
export const SIGN_UP = "Registrieren";
export const CHANGE_PASSWORD = "Passwort ändern";
export const CHANGE_EMAIL = "E-Mail-Adresse ändern";
export const RESET = "Zurücksetzen";
export const BUTTON_SEARCH = "Suche";
export const BUTTON_MENUPLAN = "Menuplan";
export const BUTTON_SHOW_PASSWORD = "";
export const SHOW_PASSWORD = "Passwort ein-/ausblenden";
// export const BUTTON_SIGN_IN_GOOGLE = "mit Google anmelden";
// export const BUTTON_SIGN_IN_FACEBOOK = "mit Facebook anmelden";
export const BUTTON_REGISTER = "";
export const CREATE_ACCOUNT = "Account erstellen";
export const BUTTON_LOAD_OLDER_COMMENTS = "... ältere Kommentare laden ....";
export const BUTTON_SAVE_COMMENT = "Kommentar speichern";
export const CREATE_RECIPE = "Neues Rezept anlegen";
export const BUTTON_LOAD_MORE_RECIPES = "Weitere Rezepte laden";
export const BUTTON_PRINTVERSION = "Druckversion";
export const BUTTON_QUANTITY_CALCULATION = "Mengenberechnung";

export const BUTTON_ADD_NOTE = "Notiz hinzufügen";
export const BUTTON_ADD_RECIPE = "Rezept hinzufügen";
export const BUTTON_ADD_PRODUCT = "Produkt hinzufügen";
export const BUTTON_SETTINGS = "Einstellungen";
export const BUTTON_POS_UP = "Position rauf";
export const BUTTON_POS_DOWN = "Position runter";
export const BUTTON_GENERATE = "Generieren";
export const CHANGE_MAIL_PASSWORD = "Mail / Passwort ändern";
export const DELETE_CHECKED_FEEDS = "markierte Feed-Einträge löschen";
export const GET_FEEDS = "Feed-Einträge holen";
export const BUTTON_TRACE = "Trace";
export const BUTTON_CHOOSE_RECIPE = "Rezept auswählen";
export const MERGE_ITEMS = "Items zusammenführen";
export const CONVERT_ITEM = "Produkt/Material umwandlen";
export const BUTTON_CLOSE = "Schliessen";
/* =====================================================================
// Seiten Titel und Untertitel
// ===================================================================== */
export const FIND_YOUR_FAVORITE_RECIPES = "Finde deine Lieblingsrezpte.";
export const COME_IN = "Hereinspaziert";
export const WE_NEED_SOME_DETAILS_ABOUT_YOU =
  "Wir brauchen noch 2, 3 Dinge von dir...";
export const PAGE_TITLE_404 = "404";
export const PAGE_SUBTITLE_404 = "Hier gibt es nichts Pirat.";
export const PAGE_TITLE_HOME = (name: string) => `Hoi ${name}`;
export const PAGE_SUBTITLE_HOME = "Lass uns kochen";
export const PAGE_TITLE_EVENTS = "Anlässe";
// export const PAGE_SUBTITLE_EVENTS = "wo kochst du morgen?";
export const PAGE_TITLE_MENUPLAN = "Menuplan";
// export const PAGE_TITLE_UNITS = "Einheiten";
// export const PAGE_SUBTITLE_UNITS = "";
export const PAGE_TITLE_UNIT_CONVERSION = "Umrechnung Einheiten";
export const PAGE_SUBTITLE_UNIT_CONVERSION =
  "Weil 276 g Zucker einfacher zu handhaben sind als 23 EL.";
export const DEPARTMENTS = "Abteilungen";
export const SO_YOU_DONT_GET_LOST_IN_THE_STORE =
  "Damit du im Laden nicht kreuz und quer umherirrst.";
export const NOTHING_WORKS_WITHOUT_US = "Ohne uns gehts gar nichts.";
export const INTRODUCING_NAME = (name: string) => `${name} stellt sich vor`;
export const PAGE_TITLE_VERIFY_EMAIL = "";
export const AYE_AYE_CAPTAIN = "Aye, aye, Captain!";
export const THANK_YOU_FOR_VERIFYING_YOUR_EMAIL =
  "Merci, dass du deine E-Mail Adresse verifziert hast.";
export const LOGIN_CHANGE = "Login ändern";
export const EVERYBODY_FORGETS_SOMETHING = "Jeder vergisst das mal...";
export const ISNT_THERE_A_CAPTAIN_MISSING_SOMEWHERE =
  "Fehlt da nicht irgendwo noch ein «Captain»?";
export const ADMIN = "Admin";
export const WELCOME_ON_THE_BRIDGE_CAPTAIN =
  "Willkommen auf der Brücke, Captain.";
export const USER_LIST = "User-Liste";
export const PAGE_SUBTITLE_USERS = "";
export const PAGE_TITLE_QUANTITY_CALCULATION = "Mengenberechnung";
export const PAGE_SUBTITLE_QUANTITY_CALCULATION = "";
export const PAGE_SUBTITLE_SHOPPING_LIST = "";
export const HELLO_NAME = (name: string) => `Hoi ${name}`;
export const SHOW_US_WHO_YOU_ARE = "Zeig uns wer Du bist.";
export const REAUTHENTICATE_DIALOG_TITLE = "Ausweis bitte...";
export const TRACE = "Trace";
export const WHERE_ARE_YOUR = "Wo bist du?";
export const TIME_TO_CLEAN_UP = "Zeit aufzuräumen";
export const REQUESTS = "Anträge";
/* =====================================================================
// Tabellen Überschriften
// ===================================================================== */
// export const COLUMN_UID = "UID";
// export const COLUMN_DEPARTMENT = "Abteilung";
export const RANK = "Reihenfolge";
export const NUMBER = "Nummer";
/* =====================================================================
// Panels
// ===================================================================== */
export const PANEL_INFO = "Infos";
export const PANEL_COOKS = "Köche";
export const PANEL_IMAGE = "Bild";
export const PANEL_NEXT_EVENTS = "Deine nächsten Anlässe";
export const PANEL_SIGN_IN = "";
export const FIELD_EMAIL = "";
export const PANEL_UNITS_BASIC = "Basic";
export const PANEL_UNITS_PRODUCTS = "Produktspezifisch";
export const PANEL_STATS = "Statistik";
export const PANEL_NOTES = "Hinweis";
export const PANEL_RECIPE = "Rezept";
export const PANEL_INFOS = "Infos";
export const PANEL_TAGS = "Tags";
export const PANEL_INGREDIENTS = "Zutaten";
export const PANEL_PREPARATION = "Zubereitung";
export const PANEL_LINKED_RECIPES = "Verknüpfte Rezepte";
export const PANEL_COMMEINTRODUCE_YOURSELFNTS = "Kommentare";
export const PANEL_PRODUCTS = "Produkte";
export const INTRODUCE_YOURSELF = "Stell dich vor";
export const GLOBAL_SETTINGS = "Globale Einstellungen";
export const SYSTEM_MESSAGE = "Systemmeldung";
export const ATENTION_IMPORTANT_ANNOUNCEMENT =
  "Achtung, Achtung, wichtige Durchsage!";
export const DELETE_FEED = "Feed-Einträge löschen";
export const DELETE_FEED_BY_DAYS = "Feed-Einträge nach Tagen löschen";
export const DELETE_FEED_SELECTIV = "Feed-Einträge selektiv löschen";
export const WHERE_USED = "Verfolgungsnachweis";
export const SYSTEM_GLOBAL_DESCRIPTION = "Sytemweite-Einstellungen verwalten.";
export const MERGE_ITEMS_DESCRIPTION =
  "Produkte/Materialien zusammenführen in Rezepten, Menüplänen, Einkaufslisten und Stammdaten.";
export const CONVERT_PRODUCT_ITEM_DESCRIPTION =
  "Produkte zu einem Material (oder umgekeht) umwandlen und die betroffenen Rezepte anpassen";

export const DELETE_FEED_DESCRIPTION =
  "Feed-Einträge löschen, welche älter als X Tage sind.";
export const WHERE_USED_DESCRIPTION =
  "Verfolgungsnachweis für Produkte und Rezepte. ";
export const JOBS = "Job-Übersicht";
export const TEMP_JOBS = "Temporäre Job-Übersicht";
export const JOBS_DESCRIPTION = "Jobs manuel ausführen.";
export const OVERVIEW_DIFFERENT_ELEMENTS = "Übersicht diverser Elemente";
export const OVERVIEW_RECIPES_DESCRIPTION = "Übersicht über alle Rezepte";
export const OVERVIEW_EVENTS_DESCRIPTION = "Übersicht über alle Alässe";
export const MAIL_CONSOLE = "Mail-Konsole";
export const MAIL_CONSOLE_DESCRIPTION =
  "Versenden von E-Mail an unsere Nutzer*innen";
export const BECAUSE_NEWSLETTER_ARE_ALWAYS_LOVED =
  "Weil wir dachten, dein Posteingang könnte ein bisschen mehr Aufregung vertragen.";
export const EDITOR = "Editor";
export const SUBJECT = "Betreff";
export const TITLE = "Titel";
export const SUB_TITLE = "Untertitel";
export const MAILTEXT = "E-Mail Nachricht";
export const RECIPIENT_TYPE = {
  0: "keine",
  1: "E-Mail-Adresse",
  2: "User-UID",
  3: "Rolle",
};
export const DIVIDE_MULTIPLE_VALUES_BY_SEMICOLON =
  "Trenne mehrere Werte mit Semmikolon.";
/* =====================================================================
// Felder
// ===================================================================== */
export const FIELD_NAME = "Name";
export const FIELD_VARIANT_NAME = "Variantenname";
export const FIELD_MOTTO = "Motto";
export const FIELD_LOCATION = "Ort";
export const FIELD_PARTICIPANTS = "Teilnehmende";
export const CREATED_FROM = "Erstellt von";
export const CREATED_AT = "Erstellt am";
export const FROM = "Von";
export const TO = "Bis";
export const FIELD_IMAGE_SOURCE = "Bildquelle";
export const EMAIL = "E-Mail-Adresse";
export const FIELD_PASSWORD = "";
export const PASSWORD = "Passwort";
export const FIELD_FIRSTNAME = "";
export const FIRSTNAME = "Vorname";
export const FIELD_LASTNAME = "";
export const LASTNAME = "Nachname";
export const DISPLAYNAME = "Anzeigename";
export const LAST_LOGIN = "letzte Anmeldung";
export const NO_LOGINS = "Anzahl Anmeldungen";

export const FIELD_PRODUCT = "Produkt";
// export const FIELD_UID = "UID";
// export const FIELD_UNIT_FROM = "Einheit Von";
// export const FIELD_UNIT_TO = "Einheit Nach";
// // export const FIELD_DENOMINATOR = "Menge von";
// export const FIELD_NUMERATOR = "Menge nach";
export const DEPARTMENT = "Abteilung";
export const USABLE = "Nutzbar";
export const SHOPPING_UNIT = "Einkaufseinheit";
export const FIELD_PORTIONS = "Portionen";
export const SOURCE = "Quelle";
export const FIELD_PREPARATION_TIME = "Vorbereitung";
export const FIELD_REST_TIME = "Ruhezeit";
export const FIELD_COOK_TIME = "Koch-/Backzeit";
export const FIELD_PRIVATE = "Privat";
export const FIELD_PRIVATE_RECIPE = "Privates Rezept";
export const FIELD_PRIVATE_RECIPE_TOOLTIP =
  "Private Rezepte können nur von dir gefunden werden.";
export const FIELD_UNIT = "Einheit";
export const FIELD_AVG_RATING = "Durchschnittliche Bewertung";
export const FIELD_YOUR_RATING = "Deine Bewertung";
export const FIELD_HINT = "Hinweis";
export const FIELD_NOTE = "Notiz";
export const FIELD_TAGS = "Tags";
export const FIELD_DAY = "Tag";
export const FIELD_SHOW_SCALE_FACTORS = "Skalierungsfaktoren anzeigen";
export const FIELD_QUANTITY = "Menge";
export const FIELD_SCALING_FACTOR = "Sk.faktor";
export const FIELD_INGREDIENT = "";
export const INGREDIENT = "Zutat";
export const FIELD_DETAILS = "Details";
export const FIELD_SECTION_NAME = "Abschnitt";
export const FIELD_STEP = "Schritt";
export const FIELD_YOUR_COMMENT = "Dein Kommentar";
export const FIELD_PLACEHOLDER_COMMENT = "Gib deinen Kommentar ein...";
export const ON_BOARD_SINCE = "an Board seit";
export const ORDER = "Reihenfolge";
export const FIELD_POSITION = "Position";
export const FIELD_NO_OF_COLUMNS = "Anzahl Spalten";
export const FIELD_MEAL = "Mahlzeit";
export const FIELD_NOTE_TYPE = "Typ der Notiz";
export const FIELD_HEAD_NOTE = "Kopfnotiz";
export const NO_OF_SERVINGS = "Anzahl Portionen";
export const FIELD_DAY_FROM = "Tag von";
export const FIELD_DAY_TO = "Tag bis";
export const FIELD_MEAL_FROM = "Mahlzeit von";
export const FIELD_MEAL_TO = "Mahlzeit bis";
export const FIELD_CONVERT_UNITS = "Einheiten umrechnen.";
export const FIELD_FEED_DELETE_AFTER_DAYS =
  "Feed löschen, die älter sind als (x) Tage";
export const NO_OF_FEED_ENTRIES = "Anzahl Feed Einträge";
/* =====================================================================
// Tooltip
// ===================================================================== */
export const TOOLTIP_ADD_POS = "Neue Position einfügen";
export const TOOLTIP_ADD_SECTION = "Neuer Abschnitt einfügen";
export const TOOLTIP_DEL_POS = "Position löschen";
export const TOOLTIP_MOVE_POS_UP = "Position nach oben verschieben";
export const TOOLTIP_MOVE_POS_DOWN = "Position nach unten verschieben";
export const PRIVATE_RECIPE = "Privates Rezept";
export const VARIANT_RECIPE = "Rezeptvariante";
/* =====================================================================
// UX Writing
// ===================================================================== */
export const UX_PRIVATE_RECIPE =
  "Rezepte sind generell privat. Wenn du dein Rezept der Community zur Verfügung stellen möchtest, klicke auf die Schaltfläche publizieren.";

/* =====================================================================
// Allgemeine Speicher Meldungen
// ===================================================================== */
export const SAVE_SUCCESS = "Daten gespeichert.";

/* =====================================================================
// Meldungen zum Event
// ===================================================================== */
export const CREATE_YOUR_EVENT = "Erstelle deinen Anlass";
export const WHAT_ARE_YOU_UP_TO = "Was hast du vor?";
export const EVENT_SAVE_SUCCESS = (eventName: string) =>
  `Event «${eventName}» wurde gespeichert.`;
export const EVENT_NOTHING_UP_TO = "Nichts vor?";
export const EVENT_PLAN_A_NEW_ONE = "Dann plane deinen nächsten Anlass.";
export const EVENT_COOK_ADDED_SUCCES = (displayName: string) =>
  `${displayName} wurde zum Team hinzugefügt.`;
export const EVENT_COOK_DELETED = "Person wurde aus Team entfernt.";
export const EVENT_COOK_ALLREADY_ADDED =
  "Die Person ist bereits Teil des Teams";
export const EVENT_SHOW_PAST_EVENTS = "Zeige vergangene Anlässe";
export const EVENT_PAST_EVENTS = "Deine vergangenen Anlässe";
export const EVENT_FUTURE_EVENTS = "Deine bevorstehende Anlässe";
export const CREATE_EVENT = "Anlass erstellen";
export const EVENT_INFO = "Informationen zum Anlass";
export const DEFINE_BASIC_EVENT_DATA =
  "Definiere die wichtigsten Eckdaten zu deinem Anlass.";
export const QUANTITY_CALCULATION_INFO = "Informationen zur Mengenberechnung";
export const COMPLETION = "Abschluss";
export const EVENT_NAME = "Name";
export const EVENT_NAME_HELPERTEXT = `Gib dem Anlass einen Namen. Beispielsweise Sola ${new Date().getFullYear()}.`;
export const MOTTO = "Motto";
export const MOTTO_HELPERTEXT = "Hat dein Anlass ein Motto?";
export const LOCATION = "Ort";
export const LOCATION_HELPERTEXT = "Wo findet der Anlass statt?";
export const DATES = "Daten";
export const START_DATE = "Start-Datum";
export const END_DATE = "Ende-Datum";
export const NO_OF_DAYS = "Anzahl Tage";
export const NO_OF_COOKS = "Köche";
export const DELETE_DATES = "Daten löschen.";
export const ADD_IMAGE = "Bild hinzufügen";
export const DELETE_IMAGE = "Bild löschen";
export const COVER_PICTURES = "Titelbild";
export const ADD_LOGO_OR_CAMP_PICTURE_HERE =
  "Füge das Logo oder Lagerbild hinzu.";
export const CONTINUE = "Weiter";
export const BACK_TO_OVERVIEW = "Zurück zur Übersicht";
export const BACK_TO_GROUPCONFIG = "Zurück zur Gruppendefinition";
export const KITCHENCREW = "Küchen-Crew";
export const COOKING_IS_COMMUNITY_SPORT =
  "Weil kochen ein Gesellschaftssport ist, füge hier deine Küchen-Crew hinzu.";
export const ADD_COOK_TO_EVENT = "Person zu Anlass hinzufügen";
export const BACK_TO_EVENT_INFO = "Zurück zu Informationen";
export const ERROR_EVENT_NAME_CANT_BE_EMPTY =
  "Der Name des Anlasses darf nicht leer sein.";
export const ERROR_EVENT_MUST_HAVE_MIN_ONE_COOK =
  "Der Anlass muss mindestens eine Person in der Küchen-Crew haben.";
export const ERROR_FROM_DATE_EMPTY = "Von-Datum darf nicht leer sein";
export const ERROR_TO_DATE_EMPTY = "Bis-Datum darf nicht leer sein";
export const ERROR_FROM_DATE_BIGGER_THAN_TO_DATE =
  "Das Von-Datum ist grösser als das Bis-Datum";
export const ERROR_FORM_VALIDATION =
  "Einige Informationen können nicht verarbeitet werden. Bitte überprüfe deine Eingabe der rot markierten Felder.";
export const ERROR_OVERLAPPING_DATES = (pos: number) =>
  `Die Daten überschneiden sich mit der Position ${pos}`;
export const EVENT_IS_BEEING_CREATED = (eventName: string) =>
  `${eventName} wird erstellt.`;
export const EVENT_IS_BEEING_SAVED = "Anlass wird gespeichert.";
export const IMAGE_IS_BEEING_UPLOADED = "Bild wird hochgeladen.";
export const RESUME_INTRODUCTION = (eventName: string) =>
  `Herzlichen Glückwunsch! Dein Anlass «${eventName}» wurde angelegt. Es geht gleich weiter. Gerne würden wir dich noch auf Folgendes hinweisen:`;
export const DONATE = "Spenden";
export const PLEASE_DONATE = "Spende erwünscht";
export const WHY_DONATE =
  "Unser Team arbeitet ehrenamtlich, um die App kostenlos anzubieten. Wir sind auf Spenden angewiesen, um die laufenden Kosten zu decken. Wenn dir die App gefällt und dein Anlassbudget es zulässt, unterstütze uns gerne mit einer Spende von Fr. 5.00. Dein Beitrag hilft, das Angebot weiterhin kostenlos anbieten zu können sowie die App am Laufen zu halten und weiter zu verbessern.";
export const NEED_A_RECEIPT =
  "Wenn du eine Quittung für den gespendeten Betrag benötigst, melde dich bitte unter hallo@chuchipirat.ch mit folgenden Angaben: Eventname und Zahlungsdatum. Wir senden dir dann gerne eine Quittung zu.";
export const THANK_YOU_1000 = "Merci 1000";
export const ROLE_TYPES = {
  admin: "Admin",
  basic: "Basic",
  communityLeader: "Community-Leader*in",
};
export const ROLES_UPDATED_SUCCSESSFULLY = "Berechtigung wurde aktualisiert";
export const YOU_CANT_UPDATE_YOUR_OWN_AUTHORIZATION =
  "Du kannst deine eigene Berechtigung nicht anpassen.";
export const MAIL_HEADER_PICTURE_SRC = "Bild-URL für Header-Bild";
export const PREVIEW = "Vorschau";
export const TEST_MAIL_SENT =
  "Testnachricht wurde versendet. Prüfe dein Postfach";
export const MAIL_SENT_TO_RECIPIENTS =
  "Mail wurde an gewählte Empfänger*innen versandt";
export const SEND_TEST_MAIL = "Test Mail senden";
export const SENDT_MAIL_TO_RECIPIENTS = "Mail senden";
export const BUTTON_TEXT = "Button-Beschriftung";
export const BUTTON_LINK = "Link für Button (Ziel)";
export const RECIPIENTS = "Empfänger";
export const NO_RECIPIENTS = "Anzahl Empfänger";
export const MAIL_TEMPLATE = "Mail-Template";
export const TIMESTAMP = "Timestamp";
export const MAILS = "E-Mails";
/* =====================================================================
// Meldungen zur Gruppen-Konfiguration
// ===================================================================== */
export const GROUP_CONFIGURATION_SETTINGS =
  "Einstellungen für die Mengenberechnung";
export const GROUP_CONFIGURATION_SETTINGS_DESCRIPTION =
  "Hier kannst du Gruppen und Unverträglichkeiten definieren. Diese kannst du danach mit den gewählten Rezepten verknüpfen. Dadurch kannst du an einem zentralen Ort die Portionen anpassen und die Änderungen werden für alle zugeordneten Rezepte übernommen. Keine Sorge. Die Anzahl Portionen kannst du auch noch zu einem späteren Zeitpunkt anpassen.";
export const WITHOUT_INTOLERANCES = "Ohne Unverträglichkeiten";
export const LACTOSE_INTOLERANCE = "Laktoseintoleranz";
export const GLUTEN_INTOLERANCE = "Glutenunverträglichkeit";
export const ADD_INTOLERANCE = "Unverträglichkeit hinzufügen";
export const ADD_DIET = "Diät-Gruppe hinzufügen";
export const TOTAL = "Total";
export const MEAT = "Fleisch";
export const VEGETARIAN = "Vegetarisch";
export const GROUPS = "Gruppen";
export const INTOLERANCE = "Unverträglichkeit";
export const DIET_GROUP = "Diät-Gruppe";
/* =====================================================================
// Meldungen zum Rezept
// ===================================================================== */
export const RECIPE_SAVE_SUCCESS = "Arrr..... Rezept wurde gespeichert";
export const THIS_FIELD_CANT_BE_EMPTY =
  "Dieses Feld darf nicht leer gelassen werden.";
export const RECIPE_NAME_CANT_BE_EMPTY =
  "Der Name des Rezept darf nicht leer sein.";
export const RECIPE_VARIANT_NAME_CANT_BE_EMPTY =
  "Der Name der Rezeptvariante darf nicht leer sein.";
export const RECIPE_SAVE_BEFORE_UPLOAD_PICTURE =
  "Bitte speichere das Rezept bevor du ein Bild hochlädst.";
export const NO_RECIPE_FOUND = "Kein passendes Rezept gefunden";
export const CREATE_A_NEW_ONE = "Erfasse ein neues!";
export const ERROR_PRODUCT_WIHTOUT_UID = (product: string) =>
  `Das Produkt ${product} besitzt keine UID. Wende dich an den Support`;
export const NO_LINKED_RECIPES = "Rezept wurde noch nicht verknüpft.";
export const BUTTON_LINK_RECIPE = "Rezept verknüpfen";
export const ERROR_POS_WITHOUT_PRODUCT = (pos: number) =>
  `Das Produkt in der Position ${pos} ist unbekannt.`;
export const ERROR_POS_WITHOUT_MATERIAL = (pos: number) =>
  `Das Material in der Position ${pos} ist unbekannt.`;
export const ERROR_RECIPE_UNKNOWN = (uid: string) =>
  `Das Rezept mit der UID ${uid} ist unbekannt.`;
export const DIALOG_TITLE_SCALE_RECIPE = "Rezept skalieren";
export const PORTIONS_TO_SCALE = "zu skalierende Portionen";
export const INFO_PANEL_TITLE_SCALE = "2*2 ist nicht immer 4";
export const INFO_PANEL_TEXT_SCALE =
  "Beachte das eine Skalierung einige Tücken mit sich bringt. So können die Zubereitungstexte allenfalls nicht mehr stimmen (enthaltene Mengen) und auch die Koch-/Backzeit kann allenfalls variieren.";
export const SCALED_RECIPE_ORIGINAL_IS = (portions: string) =>
  `skaliertes Rezept, Original ${portions} Portionen`;
export const PUBLIC_RECIPES = "öffentliche Rezepte";
export const ALL_RECIPES = "alle Rezepte";
export const PRIVATE_RECIPES = "private Rezepte";
export const MESSAGE_TO_REVIEW = "Nachricht für Review-Person";
export const THANK_YOU_FOR_YOUR_RATING = "Danke für deine Bewertung";
export const NEWEST_RECIPES = "Die neusten Rezepte";
export const START_TRACE = "Trace starten";
/* =====================================================================
// Meldungen zum Produkt
// ===================================================================== */
export const PRODUCT_CREATED = (product: string) =>
  `Produkt «${product}» wurde angelegt.`;
export const PRODUCT_EDITED = (product: string) =>
  `Produkt «${product}» wurde geändert.`;
export const PRODUCT_ADD = "Neue Zutat erstellen";
export const PRODUCT_EDIT = "Produkt anpassen";
export const RECORD_INGREDIENT_WITH_NECCESSARY_INFO =
  "Erfasse die neue Zutat gleich mit den nötigen Infos.";
export const GUIDELINES_NEW_PRODUCT = {
  line1: `Bitte prüfe folgende Punkte, bevor du ein neues Produkt anlegst:`,
  line2: `Das Produkt existiert im chuchipirat bislang nicht.`,
  line3: `Das Produkt kann im Laden gekauft werden.`,
  line4: `Der Produktnamen beinhaltet keine Marken, Mengen, Einheiten oder Zubereitungsformen.`,
  line5: `Falls bei einer nachträglichen Kontrolle auffallen würde, dass das von dir angelegte Produkt nicht den Richtlinien entspricht, wird es ohne    Voranmeldung geändert/gelöscht, was zu unvollständigen/falschen privaten Rezepten führen kann.`,
};
export const SIMILAR_PRODUCTS = "Ähnliche Produkte";
export const EXISTING_PRODUCTS = "Bestehende Produkte";
export const THERE_ARE_SIMILAR_PRODUCTS =
  "Es gibt bereits Produkte, die ähnliche Namen haben. Bitte überprüfe die angezeigte Liste und prüfe, ob das von dir gewünschte Produkt nicht bereits vorhanden ist. Falls du dein Produkt findest, kannst du dieses wählen. Ansonsten klicke auf den Button «Produkt erstellen», um ein neues Produkt zu erstellen.";
export const NEW_PRODUCT = "Neues Produkt";
export const WARNING_PRODUCT_1 =
  "Die Änderungen sind global und gelten für alle! ";
export const WARNING_PRODUCT_2 =
  " Korrigiere beim Produktname allfällige Schreibfehler. ";
export const WARNING_PRODUCT_3 =
  " Aber mach aus einem Apfel keine Peperoni! Sonst schmecken die Öpfelchüechli dann doch eher ungewohnt.";
export const SHOPPING_UNIT_INFO = "Für die Einheit «Stück» Feld leer lassen";
export const ERROR_PRODUCT_UNKNOWN = (productName: string) =>
  `Produkt ${productName} ist unbekannt.`;
export const DIALOG_ALERT_TITLE_ADD_PRODUCT = "";
export const DIALOG_ALERT_TEXT_ADD_PRODUCT =
  "Bitte nutze die Mehrzalform. Tomaten statt Tomate.";
export const ERROR_PRODUCT_WITH_THIS_NAME_ALREADY_EXISTS =
  "Es existiert bereits ein Produkt mit diesen Namen. Bitte wähle das gewünschte Produkt aus dem Dropdown aus.";
export const ERROR_MATERIAL_WITH_THIS_NAME_ALREADY_EXISTS =
  "Es existiert bereits ein Material mit diesen Namen. Bitte wähle das gewünschte Produkt aus dem Dropdown aus.";
export const DIALOG_INFO_DIET_PROPERTIES =
  "Falls du nicht sicher bist, kann du die Checkboxen auch leer lassen. Unsere Community-Leader werden die Einstellungen gegenprüfen.";
/* =====================================================================
// Meldungen zu Einheiten
// ===================================================================== */
export const UNIT_CREATED = (unit: string) => {
  return `Einheit «${unit}» wurde angelegt.`;
};
export const UNIT_CONVERSION_BASIC_CREATED =
  "Basic-Einheit Umrechnung wurde angelegt.";
export const UNIT_CONVERSION_PRODUCT_CREATED = (product: string) => {
  return `Umrechnung für «${product}» wurde angelegt.`;
};
export const BASIC = "Basic";
export const PRODUCT_SPECIFIC = "Produktspezifisch";
export const ERROR_UNIT_CONVERSION_NOT_FOUND =
  "Keine Mengenumrechnung gefunden";
export const ERROR_NO_MATCHING_UNIT_FOUND = "Keine passende Einheit gefunden";
/* =====================================================================
// Texte zu Abteilungen
// ===================================================================== */
export const CREATE_DEPARTMENT = "Abteilung anlegen";
export const DEPARTMENT_CREATED = (department: string) => {
  return `Abteilung «${department}» wurde angelegt.`;
};
/* =====================================================================
// Meldungen zum Feed
// ===================================================================== */
export const FEED = "Feed";
export const SHOULD_FEED_ENTRY_BE_DELETED = "Feed-Eintrag löschen?";
/* =====================================================================
// Meldungen zu Material
// ===================================================================== */
export const DIALOG_TITLE_MATERIAL_ADD = "Neues Material anlegen";
export const DIALOG_TITLE_MATERIAL_EDIT = "Material anpassen";
export const DIALOG_TEXT_MATERIAL =
  "Erfasse das neue Material gleich mit den nötigen Eigenschaften.";
export const CHOOSE_MATERIAL_TYPE = "Wähle den Materialtyp aus.";
export const DIALOG_EXPLANATION_MATERIAL_TYPE_CONSUMABLE =
  "Verbrauchsmaterial: Dieses Material ist nach der Zubereitung nicht mehr nutzbar. Beispiel Alupapier, Holzspiesse usw. Dieses Material kann auf die Einkaufsliste übernommen werden. ";
export const DIALOG_EXPLANATION_MATERIAL_TYPE_USAGE =
  "Gebrauchsmaterial: Dieses Material ist üblicherweise in der Küche vorhanden oder kann von zu Hause mitgenommen werden. Diese Art von Material zeichnet sich darin aus, dass es nach einer Verwendung erneut genutzt werden kann. Beispiel Cakeform, Waffeleisen usw. Dieses Material landet auf die Materialliste.";
export const MATERIAL_CREATED = (material: string) =>
  `Material «${material}» wurde angelegt.`;
export const PRODUCT_CONVERTED_TO_MATERIAL = (productName: string) =>
  `Product «${productName}» wurde in ein Material umgewandelt.`;
/* =====================================================================
// Texte zu Menüplan
// ===================================================================== */
export const MENUPLAN_SETTINGS_SHOW_RECIPE_PICTURE = "Rezeptbilder anzeigen";
export const MENUPLAN_NOTE_TYPE_BIRTHDAY = "Geburtstag";
export const MENUPLAN_NOTE_TYPE_PREPARE = "Vorbereitung";
export const MENUPLAN_NOTE_TYPE_SHOPPING = "Einkaufen";
export const MENUPLAN_NOTE_TYPE_IDEA = "Idee";
export const MENUPLAN_NOTE_TYPE_HINT = "Hinweis";
export const MENUPLAN_DIALOG_ADD_RECIPE = (name: string) => {
  return `Rezept: ${name}`;
};
export const MENUPLAN_DRAWER_SEARCH_RECIPE_TITLE = "Rezept suchen";
export const SHOW_DETAILS = "Details anzeigen";
export const ADD_MEAL = "Mahlzeit hinzufügen";
export const COMMENT = "Kommentar";
export const COMMENTS = "Kommentare";
export const NEW_MENU = "Neues Menü";
export const ADD_RECIPE = "Rezept hinzufügen";
export const EDIT_MENUE = "Menü bearbeiten";
export const ADD_PRODUCT = "Produkt hinzufügen";
export const ADD_MATERIAL = "Material hinzufügen";
export const RECIPES_DRAWER_TITLE = "Was für ein Rezept suchst du?";
export const DIALOG_CHOOSE_MENUES_TITLE = "Wann gibt es dieses Rezept?";
export const DIALOG_PLAN_RECIPE_PORTIONS_TITLE =
  "Für wen planst du das Rezept ein?";
export const DIALOG_PLAN_GOODS_PORTIONS_TITLE =
  "Wie viele Portionen willst du planen?";
export const MENUE = "Menü";
export const ALL = "Alle";
export const FIX_PORTIONS = "Fixe Portionen";
export const ON_DATE = "Am";
export const KEEP_PLANED_PORTIONS_IN_SYNC = "Für alle Menüs gleich";
export const FACTOR = "Faktor";
export const TOTAL_PORTIONS = "Total Portionen";
export const FACTOR_TOOLTIP =
  "Mit dem Faktor kannst du die Anzahl Portionen beeinflussen. So kannst du zum Beispiel mit dem Faktor 0.5 halb so viele Portionen zubereiten oder mit dem Faktor 1.2 etwas mehr (grosser Hunger).";
export const YOUR_SELECTION_MAKES_X_SERVINGS = "Deine Auswahl ergibt:";
export const BACK = "zurück";
export const PLEASE_PROVIDE_VALID_FACTOR = "Faktor ungültig";
export const NO_MENUES_MARKED =
  "Es wurde kein Menü markiert, für welches das Rezept eingeplant werden soll.";
export const MISSING_FACTOR =
  "Es gibt ausgewählte Einträge, bei denen fehlt der Faktor. Für diese können die Portionen nicht berechnet werden. Bitte trage einen Faktor ein.";
export const NO_GROUP_SELECTED = "Es wurde keine Gruppe ausgewählt. ";
export const NO_PORTIONS_GIVEN = "Es wurde keine Anzahl Portionen angegeben";
export const DELETE_MENUE = "Menü löschen";
/* =====================================================================
// Alert Texte und Überschriften
// ===================================================================== */
export const ALERT_TITLE_UUPS = "Uups... da ging was schief.";
export const ALERT_TITLE_WAIT_A_MINUTE = "Warte mal kurz....";
export const ONE_TWO_TRHEE_DONE = "1, 2, 3... Erledigt.";
export const ALERT_TITLE_MUTINY_ON_THE_HIGH_SEAS = "Meuterei auf hoher See...";
export const WELCOME_ON_BOARD = "Willkommen an Bord";
export const ALERT_TATSCH_BANG_DONE = "Tätsch-Bäng-Meringue: Erledigt!";
export const BACKSPELLED = "Zurück-buchstabiert";
export const CHANGE_UNDONE = "Die Änderung wurde rückgängig gemacht.";
export const NEW_EMAIL_IDENTICAL =
  "Die E-Mail-Adresse ist mit der bestehenden identisch.";
export const EMAIL_RECOVERED =
  "Die alte E-Mail-Adresse ist wieder deine Login-Adresse. Sicherheitshalber wurdest du abgemeldet. Hier kannst du dich wieder anmelden:  ";
export const ALERT_TEXT_IMAGE_SAVE_FIRST =
  "Bitte speichere den Anlass bevor du ein Bild hochlädst.";
export const ALERT_TEXT_IMAGE_SOURCE =
  "Du kannst die URL eines Bildes im Netz angeben. Suche dafür ein passendes Bild, klicke mit der rechten Maustaste und wähle im Kontextmenü den Eintrag Bildadresse kopieren. Diese Adresse kannst du im Feld oberhalb einfügen. ";
export const TOOLTIP_RECIPE_IMAGE_SOURCE =
  "Du kannst die Adresse kopieren, indem du auf einer beliebigen Seite einen Rechtsklick auf ein Bild machst und den Eintrag 'Bildadresse kopieren' wählst.";
export const HELPERTEXT_RECIPE_IMAGE_SOURCE =
  "Füge hier die Web-Adresse eines Bildes ein.";
export const CONFIRM_CHANGES_ARE_LOST =
  "Bist du sicher, dass du abbrechen möchtest? Allfällige Änderungen gehen verloren.";
export const CONFIRM_DELETE_PICTURE =
  "Bist du sicher, dass du das Bild löschen möchtest?";
export const DELETE_PICTURE = "Bild löschen";
/* =====================================================================
// Texte zum Admin Bereich
// ===================================================================== */
export const TRACE_RESULT = "Trace Ergebnis";
export const MERGE_PRODUCT_SELECTION = "Produkteauswahl";
export const MERGE_MATERIAL_SELECTION = "Materialauswahl";

export const MERGE_ITEM_EXPLANATION = (item: string) =>
  `Das ${item} A wird zu ${item} B. Alle Vorkommnisse des ${item} A (Rezepte, Menüplan, Einkaufslisten) werden angepasst. Nach erfolgreicher Änderungen der betroffenen Objekte wird das ${item} A gelöscht und steht nicht mehr zur Verfügung.`;
export const CONVERT_ITEM_EXPLANATION = (fromItem: string, toItem: string) =>
  `Das gewählte ${fromItem} wird in ein ${toItem} umgewandelt. Dabei werden alle Rezepte, Menüpläne, Einkauf- und Materiallisten angepasst.`;
export const MERGE_PRODUCT_A = "Produkt A";
export const MERGE_PRODUCT_B = "Produkt B";
export const CHANGED_DOCUMENTS = "Geänderte Dokumente";
export const FOUND_DOCUMENTS = "Gefundene Dokumente";
export const MERGE_ERROR_SAME_ITEMS = (item: string) =>
  `${item} A und ${item} B sind identisch. Ein Zusammenführen macht daher keinen Sinn.`;
export const GLOBAL_SETTINGS_ALLOW_SIGNUP_LABEL = "Neu-Anmeldung ermöglichen";
export const GLOBAL_SETTINGS_ALLOW_SIGNUP_DESCRIPTION =
  "Personen können ein Login für den chuchipirat erstellen (Neuanmeldung/Registrierung).";
export const GLOBAL_SETTINGS_MAINTENANCE_MODE_LABEL = "Wartungsmodus";
export const GLOBAL_SETTINGS_MAINTENANCE_MODE_DESCRIPTION =
  "Im Wartungsmodus ist eine (Neu-)Anmeldung nicht möglich.";
export const ACTIVATE_SUPPORT_USER = "Support-User aktivieren";
export const ACTIVATE_SUPPORT_USER_DESCRIPTION =
  "Den Support-User für einen Anlass berechtigen.";
export const SIGN_OUT_ALL_USERS = "Alle Benutzer*innen abmelden";
export const SIGN_OUT_ALL_USERS_DESCRIPTION =
  "Für alle Benutzer*innen (ausser Admin) ein Log-Out durchführen.";
export const SIGN_OUT_EVERYBODY = "Alle abmelden";
export const USERS_ARE_LOGGED_OUT = "Benutzer*innen werden abgemeldet.";
/* =====================================================================
// Diverse (längere) Texte
// ===================================================================== */
export const ENTRY_DELETED = "Eintrag gelöscht.";
export const WELCOME_ON_BOARD_REDIRECT =
  "Wir erwarten dich auf der Brücke. Bitte melde dich auf der nächsten Seite erneut an.";
/* =====================================================================
// Navigation
// ===================================================================== */
// export const NAVIGATION_EVENTS = "Anlässe";
// export const NAVIGATION_RECIPES = "Rezepte";
export const NAVIGATION_UNITS = "Mengeneinheiten";
export const NAVIGATION_UNIT_CONVERSION = "Mengenumrechnungen";
export const NAVIGATION_PRODUCTS = "Produkte";
export const NAVIGATION_DEPARTMENTS = "Abteilungen (Einkauf)";
export const SIGN_OUT = "Abmelden";
// export const NAVIGATION_SIGN_IN = "Anmelden";
export const NAVIGATION_USER_PROFILE = "Profil";
export const NAVIGATION_REQUEST_OVERVIEW = "Anträge";
export const NAVIGATION_SYSTEM = "System";
export const NAVIGATION_ADMIN = "Admin";
export const NAVIGATION_USERS = "Users";
export const NAVIGATION_HELP = "Hilfe";
export const REFRESH_APP_TILE = "Neue Version verfügbar";
export const REFRESH_APP_TEXT =
  "Es gibt eine neue Version des chuchipirat. Um die App zu aktualisieren, muss ein Refresh durchgeführt werden. Sichere daher vorher allfällige Änderungen. Mit OK bestätigst du, dass die Seite neu geladen werden kann.";

/* =====================================================================
// Statistik 
// ===================================================================== */
export const STATS = "Statistik";
export const HOME_STATS_CAPTIONS = {
  noUsers: "Köche",
  noEvents: "Anlässe",
  noIngredients: "Lebensmittel",
  noRecipesPublic: "öffentliche Rezepte",
  noRecipesPrivate: "private Rezepte",
  noRecipesVariants: "Anlassvarianten Rezepte",
  noShoppingLists: "Generierte Einkaufslisten",
  noParticipants: "bekochte Personen",
  noPortions: "Geplante Portionen",
  noPlanedDays: "Geplante Anlasstage",
  noMaterials: "Materialien",
  noMaterialLists: "Generierte Materiallisten",
};
/* =====================================================================
// Meldungen rund um SignIn/SignUp
// ===================================================================== */
export const VERIFY_YOUR_EMAIL =
  "Kontrolliere bitte deine E-Mail: Schau in deinem Postfach nach (auch im Spam-Ordner), ob du eine Nachricht bekommen hast. Klicke auf den Link in der E-Mail, um deine E-Mail-Adresse zu bestätigen. Falls nötig, kannst du die Bestätigungs-E-Mail erneut anfordern.";
export const VERIFICATION_EMAIL_SENT =
  "Die E-Mail zur Bestätigung wurde verschickt. Schau in deinen E-Mails (auch im Spam-Ordner) nach der Bestätigungs-E-Mail. Nachdem du die E-Mail bestätigt hast, aktualisiere bitte diese Seite.";
export const NO_AUTH_REDIRECT_TO_HOME =
  "Für die angeforderte Seite hast du keine Berechtigung. Du wirst automatisch umgeleitet.";
export const NOT_REGISTERED_YET_SIGN_UP = "Noch keinen Account? Melde dich an!";
export const EMAIL_HAS_BEEN_CHANGED = "Deine E-Mail-Adresse wurden geändert. ";
export const LOGIN_CHANGE_ARE_YOU_READY = "Bereit für ein neues Login?";
export const SIGN_IN_WHY_REAUTHENTICATE = "Bitte authentifiziere dich erneut.";
export const LOGIN_SUCCESSFULL = "Login erfolgreich";
export const SIGN_UP_NOT_ALLOWED_TITLE = "Wohin des Weges Pirat?";
export const SIGN_UP_NOT_ALLOWED_TEXT =
  "Der chuchipirat befindet sich in der Beta-Phase. Die Anmeldung ist daher auf wenige Personen eingeschränkt. Melde dich unter hallo@chuchipirat für mehr Infos.";
export const MAINTENANCE_MODE_SIGN_UP_NOT_ALLOWED =
  "chuchipirat macht Pause: Wartungsmodus aktiviert";
export const MAINTENANCE_MODE_SIGN_UP_NOT_ALLOWED_TEXT =
  "Oh-oh! Der chuchipirat macht gerade eine kleine Pause, um seine digitalen Schaltkreise zu überprüfen und ein paar virtuelle Schrauben nachzuziehen. Bitte warte einen Moment, während er seine Wartungsarbeiten durchführt und dann mit frischer Energie zurückkehrt!";
/* =====================================================================
// Meldungen zu Password
// ===================================================================== */
export const PASSWORD_RESET_EXPIRED =
  "Deine Anfrage zum Zurücksetzen des Passworts ist abgelaufen oder der Link wurde bereits verwendet. Versuche erneut das ";
export const PASSWORD_MAGIC_LINK_IN_INBOX =
  "Schau in deinem E-Mail-Postfach für den magischen Link, um das Passwort zurückzusetzen.";
export const PASSWORD_HAS_BEEN_CHANGED = "Das Passwort wurde geändert.";
export const PASSWORD_LINK_SENT =
  "Schau in deinem E-Mail-Postfach für den magischen Link um das Passwort zurückzusetzen.";
export const PASSWORD_CHANGE_ARE_YOU_READY = "Bereit für ein neues Passwort?";
export const PASSWORD_WHERE_SEND_MAGIC_LINK =
  "Wohin soll der magische Link zugestellt werden?";
export const PASSWORD_CHANGE = "Passwort ändern";
export const PASSWORD_RESET = "Passwort zurücksetzen";
export const HAVE_YOU_FORGOTEN_YOUR_PASSWORD =
  "Hast du möglicherweise dein Passwort vergessen? ";

export const PASSWORD_HOW_STRONG_IS_IT = "Wie stark ist dein Passwort: ";
export const PASSWORD_STRENGTH_METER = {
  WEAK: "Schwach",
  SUFFICENT: "Ausreichend",
  GOOD: "Gut",
  STRONG: "Stark",
};
/* =====================================================================
// Rezept 
// ===================================================================== */
export const UNIT_MIN = "Min";
export const ERROR_PORTIONS_NEGATIV =
  "Die Anzahl Portionen muss grösser sein als 0.";
export const ERROR_PORTIONS_NOT_NUMERIC =
  "Gib einen numerischen Wert für Portionen an.";
export const ERROR_NO_INGREDIENTS_GIVEN =
  "Keine Zutaten. Bitte gib die nötigen Zutaten an.";
export const DELETE_RECIPE = "Rezept löschen";
export const PUBLISH_RECIPE = "Rezept für die Community veröffentlichen";
export const REPORT_ERROR = "Fehler melden";
export const REPORT_ERROR_DESCIRPTION =
  "Hast du einen Fehler im Rezept gefunden? Wir würden uns freuen, wenn du uns dabei hilfst, es zu verbessern! Bitte beschreibe den Fehler so genau wie möglich, damit wir ihn schnell beheben können. Deine Rückmeldung ist uns wichtig. Vielen Dank!";
export const ERROR_DESCRIPTION = "Beschreibung des Fehlers im Rezept:";

export const SHOW_OPEN_REQUESTS = "Zeige offene Anträge";
export const SEND_RECIPE_TO_REVIEW = "Rezept für Review einreichen";
export const IMAGE_SOURCE = "Bildquelle: ";
export const IMAGE_MAY_BE_SUBJECT_OF_COPYRIGHT =
  "Das Bild ist eventuell urheberrechtlich geschützt.";
export const PUBLISH_RECIPE_RULES_PART1 =
  "Merci, dass du dein Rezept der Community zur Verfügung stellen willst. " +
  "Damit das Rezept öffentlich geschaltet werden kann, wird es von den Community-Leader*innen geprüft. Mit dieser Prüfung wird sichergestellt, dass Rezepte einem gewissen Standard entsprechen, sodass sich die anderen Anwender*mit gutem Gewissen von der Rezeptdatenbank bedienen können. " +
  "Damit ein Rezept veröffentlicht wird, müssen diese Punkte erfüllt sein:";
export const PUBLISH_RECIPE_RULES_BULLET_LIST =
  "Quellenangabe erforderlich: Das Rezept muss eine genaue Quellenangabe haben, vorzugsweise von bekannten Plattformen wie Betty Bossi, Swissmilk usw. oder aus einem Kochbuch." +
  "•Klare Zubereitungsschritte: Die Anleitung muss logisch strukturiert und einfach verständlich sein." +
  "•Flexible Mengenangaben: Die Zubereitungsschritte sollten keine festen Mengenangaben enthalten, um eine problemlose Anpassung der Portionen zu ermöglichen." +
  "•Gepflegte Rezeptattribute: Alle relevanten Attribute wie Menütyp und Dauer müssen sorgfältig gepflegt sein." +
  "•Einzigartiges Rezept: Das Rezept sollte bisher nicht in der öffentlichen Sammlung vorhanden sein.";
export const PUBLISH_RECIPE_RULES_PART2 =
  "Falls bei der Prüfung Fragen aufkommen, würde sich die entsprechende Person bei dir per Mail melden. Falls du willst, kannst du der prüfenden Person eine Nachricht mit dem Rezept zukommen lassen. ";
export const PUBLISH_RECIPE_RULES_PART3 = "Bitte beachte:";
export const PUBLISH_RECIPE_RULES_PART4 =
  "Sobald das Rezept öffentlich ist, wirst du dieses nicht mehr ändern können. ";
export const PUBLISH_RECIPE_RULES_PART5 =
  "Danke für dein Engagement. Beste Grüsse";
export const PUBLISH_RECIPE_RULES_PART6 = "Dein chuchipirat";
export const PUBLISH_RECIPE_REQUEST_CREATED = (requestNo: number) =>
  `Der Antrag ${requestNo} wurde erfolgreich erzeugt.`;
export const REPORT_ERROR_RECIPE_REQUEST_CREATED = (requestNo: number) =>
  `Die Meldung ${requestNo} wurde erfolgreich erzeugt.`;

export const IS_LACTOSEFREE = "Laktosefrei";
export const HAS_LACTOSE = "mit Laktose";
export const LACTOSE = "Laktose";
export const IS_GLUTENFREE = "Glutenfrei";
export const HAS_GLUTEN = "mit Gluten";
export const GLUTEN = "Gluten";
export const DIET_PROPERTIES = "Diät Info";
export const IS_VEGETARIAN = "Vegetarisch";
export const IS_VEGAN = "Vegan";
export const HAS_MEAT = "mit Fleisch";
export const IS_MEAT = "ist Fleisch";
export const TIPS_AND_TAGS = "Tipps & Tags";
export const DIET = "Diät";
export const NONE_RESTRICTION = "Keine";
export const INTOLERANCES = "Unverträglichkeiten";
export const PRODUCT_PROPERTY = "Produkteigenschaft";
export const HELPTER_TEXT_RECIPE_SOURCE =
  "Woher hast du das Rezept? URL, Buch, Zeitschrift usw.";
export const DELETE_EVENT = "Anlass löschen";
// Für DB Errors
export const DB_DOCUMENT_DELETED = "Dokument wurde gelöscht.";
/* =====================================================================
// Texte zum Profil
// ===================================================================== */
export const FOUND_TREASURES = "Gefundene Schätze";
export const RECIPES_CREATED_PUBLIC = "erfasste öffentliche Rezepte";
export const RECIPES_CREATED_PRIVATE = "erfasste private Rezepte";
export const EVENTS_PARTICIPATED = "bekochte Anlässe";
export const FOUND_BUGS = "Gefundene Bugs";
export const COMMENTS_LEFT = "hinterlassene Kommentare";
export const USER_PROFILE_ERROR_DISPLAYNAME_MISSING =
  "Bitte gib einen Anzeigename an.";
export const USER_PROFILE_SUCCESSFULLY_UPDATED =
  "Dein Profil wurde gespeichert. ";
export const PROFILE_PICTURE_UPLOAD_SUCCESS =
  "Das Bild wurde erfolgreich hochgeladen. Es kann einige Minuten dauern, bis es überall korrekt angezeigt wird.";
/* =====================================================================
// Meldungen zu Einheiten
// ===================================================================== */
export const UNIT_CREATE = "Neue Einheit anlegen";
export const UNIT_CREATE_EXPLANATION =
  "Erfasse die neue Einheit gleich mit den nötigen Infos.";
export const ERROR_UNIT_CONVERSION_TYPE_MISSING =
  "Kein Typ gewählt. Speichern nicht möglich.";
export const CREATE_NEW_UNIT_CONVERSION = "Neue Einheitenumrechnung anlegen";
export const METRIC_SYSTEM = "Metrisches System";
export const HINT_CREATE_IN_METRIC_SYSTEM =
  "Bitte erfasse die Umrechung mit einer Zieleinheit im metrischen Einheitssystem.";

/* =====================================================================
// Meldungen zu Mengenberechnung
// ===================================================================== */
export const QUANTITY_CALCULATION_ERROR_NO_RECIPES =
  "Der Menüplan enthält keine Rezepte.";
/* =====================================================================
// FormValidation
// ===================================================================== */
export const GIVE_UNIT = "Bitte Einheit angeben.";
export const GIVE_PRODUCT = "Bitte Produkt wählen.";
export const GIVE_GREATE_ZERO = "Bitte Wert grösser 0 angeben.";
export const GIVE_DEPARTMENT = "Bitte Abteilung wählen";
export const GIVE_DEPARTMENT_NAME = "Bitte Abteilungsname angeben.";
export const FORM_DATE_TO_LOWER_THAN_DATE_FROM =
  "«Tag von» ist grösser als «Tag bis».";
export const FORM_MEAL_TO_LOWER_THAN_MEAL_TO =
  "«Mahlzeit bis» findet vor «Mahlzeit von» statt.";
export const FORM_GIVE_MATERIAL = "Bitte Materialname eingeben";
export const FORM_GIVE_MATERIAL_TYPE = "Bitte Materialtyp wählen";
export const PLEASE_GIVE_VALUE_FOR_FIELD = (fieldName: string) =>
  `Bitte ${fieldName} eingeben.`;
/* =====================================================================
// User hinzufügen // AddUser
// ===================================================================== */
export const GIVE_VALID_EMAIL = "Bitte gültige E-Mail-Adresse angeben";
export const ADD_PERSON_TO_TEAM = "Person ins Team aufnehmen";

export const USER_ADD_BY_EMAIL = "Gib hier die E-Mail-Adresse der Person ein.";
export const USER_MUST_BE_REGISTERED =
  "Bitte beachte, dass die Person einen gültigen Account besitzen muss, bevor du sie dem Team hinzufügen kannst.";
export const USER_NOT_IDENTIFIED_BY_EMAIL =
  "Die Person konnte anhand der E-Mail-Adresse nicht eindeutig ermittelt werden.";
export const NO_USER_WITH_THIS_EMAIL =
  "Wir kennen keine Person mit dieser E-Mail-Adresse. Hat die Person einen Account?";
export const YOU_CANNOT_ADD_YOURSELF =
  "Du kannst dich nicht noch einmal hinzufügen.";
/* =====================================================================
// Feed Texte
// ===================================================================== */
export const FEED_TITLE = {
  USER_CREATED: "Arrr.... Neue*r Kapitän*in",
  RECIPE_CREATED: "Neues Rezept",
  RECIPE_PUBLISHED: "Neues Rezept",
  RECIPE_RATED: "Neues Rating für",
  EVENT_CREATED: "Gut geplant ist halb gewonnen",
  EVENT_COOK_ADDED: "Küchen-Crew vergrössert",
  MENUPLAN_CREATED: "Was wird wann gekocht?",
  SHOPPINGLIST_CREATED: "Einkaufen ist angesagt",
};

export const FEED_TEXT = {
  USER_CREATED: "ist neu mit an Bord.",
  RECIPE_PUBLISHED: (textElements: string[]) =>
    `hat das Rezept «${textElements[0]}» der Community beigesteuert.`,

  RECIPE_CREATED: (textElements: string[]) =>
    `hat das Rezept «${textElements[0]}» erfasst.`,

  RECIPE_RATED: (textElements: string[]) => {
    switch (textElements[1]) {
      case "5":
        return `findet es superlecker und vergibt ${textElements[1]} ⭐️.`;
      case "4":
      case "4.5":
        return `mag es und vergibt ${textElements[1]} ⭐️.`;
      case "3":
      case "3.5":
        return `ist mässig überzeugt und vergibt ${textElements[1]} ⭐️.`;
      case "2":
      case "2.5":
        return `mag lieber was anderes und vergibt ${textElements[1]} ⭐️.`;
      case "1":
      case "1.5":
      case "0.5":
        return `mag das anscheinend nicht und vergibt ${textElements[1]} ⭐️`;
      case "0":
        return "hat seine Meinung geändert und das Rating entfernt.";
      default:
        return "?";
    }
  },
  EVENT_CREATED: (textElements: string[]) =>
    `hat den Anlass «${textElements[0]}» erstellt.`,
  EVENT_COOK_ADDED: (textElements: string[]) =>
    `wurde in das Team «${textElements[0]}» aufgenommen.`,
  MENUPLAN_CREATED: (textElements: string[]) =>
    `Plant gerade den ${textElements[0]}`,
  SHOPPINGLIST_CREATED: (textElements: string[]) =>
    `kauft ${textElements[0]} ein.`,
};
/* =====================================================================
// Texte zum Handling mit Bilder/Images
// ===================================================================== */
export const QUESTION_DELETE_IMAGE =
  "Sicher, dass du das bestehende Bild löschen möchtest?";
export const PICTURE_HAS_BEEN_DELETED = "Bild wurde gelöscht.";
/* =====================================================================
// Landing Page
// ===================================================================== */
export const LANDING_LEAD_TEXT =
  "Der chuchipirat ist dein digitaler Küchenassistent, der mit seiner herausragenden Planungsunterstützung das Kochen für Gruppen auf ein neues Level hebt. Von der Rezeptorganisation bis zur stressfreien Einkaufsliste. Planen für Gruppen war noch nie so einfach!";
// "Kochst du gerne? Für grössere Gruppe? In Lagern, Kursen oder bei Anlässen? Dann wird dich der chuchipirat unterstützen.";
export const LOVE_TO_COOK =
  "Liebst du es zu kochen, besonders für grössere Gruppen während Lagern, Kursen oder Veranstaltungen? Dann hilft dir der chuchipirat:";
export const LANDING_RECIPES_BLOCK_TITLE = "Entdecke neue Rezepte";
export const LANDING_RECIPES_BLOCK_TEXT =
  "Finde inspirierende Rezepte, die von anderen begeisterten Köchen bereits ausprobiert wurden. Und wenn du dein ultimatives Super-Gericht kreierst, teile es mit der Community – geteilte Genüsse sind doppelte Freude!";
export const LANDING_GROUP_CONFIG_TITLE =
  "Individuelle Anpassungen für jedes Bedürfnis";
export const LANDING_GROUP_CONFIG_TEXT =
  "Stelle dir vor, du könntest mühelos Diäten und Intoleranzen berücksichtigen, um sicherzustellen, dass alle kulinarischen Vorlieben respektiert werden. Der chuchipirat ermöglicht es dir, personalisierte Ernährungsvorgaben zu erstellen und gemeinsam köstliche Mahlzeiten zu planen, die die individuellen Essgewohnheiten deiner Gruppe berücksichtigen.";
export const LANDING_GROUP_SIZE_TITLE = "Flexibilität bei der Gruppengrösse";
export const LANDING_GROUP_SIZE_TEXT =
  "Ob du für dich selbst kochst oder eine grosse Gruppe bewirtest – mit dem chuchipirat kannst du die Gruppengrösse spielend leicht anpassen. Die automatische Mengenberechnung stellt sicher, dass jede Portion perfekt ausbalanciert ist, unabhängig davon, ob du für zwei oder zwanzig kochst.";
export const LANDING_MENUPLAN_TITLE = "Einfache Menüplanung";
export const LANDING_MENUPLAN_TEXT =
  "Erstelle im Handumdrehen Menüpläne für deine Kochvorhaben. Definiere die Portionsgrössen, kombiniere verschiedene Rezepte zu einem kulinarischen Höhepunkt und plane, wann jedes Gericht serviert wird.";
export const LANDING_SCALING_TITLE = "Skalierbare Rezepte";
export const LANDING_SCALING_TEXT =
  "Ob du für dich selbst kochst oder für eine riesige Gruppe – passe die Rezepte einfach an deine Bedürfnisse an. Der chuchipirat ermöglicht eine mühelose Skalierung für jede Gelegenheit.";
export const LANDING_SHOPPINGLIST_TITLE = "Spare Zeit beim Einkaufen";
export const LANDING_SHOPPINGLIST_TEXT =
  "Mit nur wenigen Klicks erstellt der chuchipirat einen Einkaufszettel, basierend auf deinem Menüplan. Nie wieder den Überblick über die Zutaten verlieren – alles ist übersichtlich nach Abteilungen sortiert.";
export const LANDING_SOCIAL_TITLE = "Gemeinsam kochen macht mehr Spass";
export const LANDING_SOCIAL_TEXT =
  "Teile deine kulinarischen Abenteuer mit Freunden! Bearbeitet gemeinsam euer Happening und organisiert alles, was für euren Anlass wichtig ist. Kochen wird so zu einem gemeinschaftlichen Erlebnis.";
export const LANDING_OFFLINE_TITLE = "Offline? Kein Problem";
export const LANDING_OFFLINE_TEXT =
  "Keine Internetverbindung? Kein Problem. Exportiere deine Rezepte, Berechnungen und Einkaufsliste als handliches PDF, damit du immer Zugriff darauf hast – egal wo du gerade kochst. Der chuchipirat begleitet dich überallhin.";
/* =====================================================================
// Footer
// ===================================================================== */
export const FOOTER_CREATED_WITH_JOY_OF_LIFE = {
  part1: "Für und mit ",
  linkText: "Lebensfreu(n)de",
  part2: " entwickelt",
};
export const FOOTER_QUESTIONS_SUGGESTIONS = {
  TITLE: "Fragen und Anregungen?",
  CONTACTHERE: "Melde dich hier:",
  OR_LOOK_HERE: "oder schau im",
  HELPCENTER: "Helpcenter",
  OVER: "vorbei",
};
/* =====================================================================
// Firebase Meldungen in Schöntext
// ===================================================================== */
export const FIREBASE_MESSAGES = {
  WEAK_PASSWORD:
    "Passwort zu schwach: Passwort muss aus mindestens 6 Zeichen bestehen.",
  INVALID_EMAIL: "E-Mail Adresse ungültig",
  EMAIL_ALREADY_IN_USE:
    "Es besteht bereits ein Account mit dieser Adresse. Setze das Passwort zurück, falls du dich nicht mehr daran erinnerst.",
  USER_DISABLED: "User ist deaktiviert. Melde dich unter hallo@chuchipirat.ch",
  USER_NOT_FOUND: "User existiert nicht.",
  WRONG_PASSWORD: "Passwort falsch.",
  ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL:
    "Es existiert bereits ein Konto mit der gleichen E-Mail-Adresse. Versuche dich anzumelden und verknüpfe die Social-Media-Accounts in deinem Profil miteinander.",
  PERMISSION_DENIED: "Dir fehlt die nötige Berechtigung, für diese Aktion.",
  INVALID_ACTION_CODE:
    "Der Verifizierungscode ist ungültig. Dies kann passieren, wenn der Code fehlerhaft ist, abläuft oder bereits verwendet wurde.",
  UNAVAILABLE:
    "Bist du bist Offline? Damit du die Daten bearbeiten und lesen kannst, musst du mit dem Internet verbunden sein.",
  REQUIRES_RECENT_LOGIN:
    "Dieser Vorgang ist sensibel und erfordert eine aktuelle Authentifizierung. Melde dich erneut an, bevor du diese Anfrage erneut versuchst.",
  TOO_MANY_REQUESTS:
    "Der Zugriff auf dieses Konto wurde aufgrund vieler fehlgeschlagener Anmeldeversuche vorübergehend deaktiviert. Du kannst das Konto entsperren, indem du dein Passwort zurücksetzt, oder du kannst es später erneut versuchen.",
  INTERNAL_ERROR:
    "Fehler bei der Anmeldung: Die eingegebenen Anmeldeinformationen sind ungültig.",
};
/* =====================================================================
// Texte zum Admin-Bereich 
// ===================================================================== */
export const DB_INDICES = "DB Indizes";
export const DB_INDICES_DESCRIPTION = "DB Indizes aufbauen";
/* =====================================================================
// Sonstiges
// ===================================================================== */
export const TYPE_UNKNOWN = "Typ unbekannt";
export const REDIRECTION_IN = "Umleitung in";
export const OR_CLICK = "oder klicke ";
export const HERE = "hier";
export const IF_YOU_ARE_IMPATIENT = "falls du ungeduldig bist";
export const ATTENTION = "Achtung";
export const VOTE = "Stimme";
export const VOTES = "Stimmen";
export const TAG = "Tag";
export const ADD = "hinzufügen";
export const SETTINGS = "Einstellungen";
export const MEAL = "Mahlzeit";
export const NOTE = "Notiz";
export const RECIPE = "Rezept";
export const RECIPES = "Rezepte";
export const RECIPETYPE = "Rezepttyp";
export const CONSIDER_INTOLERANCES = "Unverträglichkeit berücksichtigen";
export const RESTRICTIONS = "Einschränkungen";
export const NEW_RECIPE = "Neues Rezept";
export const INGREDIENTS = "Zutaten";
export const ALLERGENS = "Allergene";
export const PRODUCT = "Produkt";
export const PRODUCTS = "Produkte";
export const QUANTITY_CALCULATION = "Mengenberechnung";
export const SHOPPING_LIST = "Einkaufsliste";
export const MENUPLAN = "Menüplan";
export const MATERIAL_LIST = "Materialliste";
export const EVENT_INFO_SHORT = "Infos zum Anlass";
export const PLANED_RECIPES = "Verwendete Rezepte";
export const SUFFIX_PDF = ".pdf";
export const FOR_ACCUSATIVE = "Für";
export const PORTIONS = "Portionen";
export const PORTION = "Portion";
export const ORIGINAL = "Original";
export const SCALED = "skaliert";
export const PREPARATION = "Zubereitung";
export const MATERIAL = "Material";
export const MATERIAL_TYPE = "Materialtyp";
export const MATERIAL_TYPE_CONSUMABLE = "Verbrauchsmaterial";
export const MATERIAL_TYPE_USAGE = "Gebrauchsmaterial";
export const MATERIAL_TYPE_KEY_TEXT = {
  0: "-",
  1: MATERIAL_TYPE_CONSUMABLE,
  2: MATERIAL_TYPE_USAGE,
};
export const OUTDOOR_KITCHEN_SUITABLE = "Geeignet für Outdoor-Küche";
export const SHOW_ONLY_MY_RECIPES = "Nur meine Rezepte anzeigen";
export const ORIGINAL_QUANTITIES = "Original Mengen";
export const SCALED_QUANTITIES = "Hochgerechnete Mengen";
export const CONVERT_UNITS = "Einheiten umrechnen";
export const CONVERT_UNITS_EXPLANATION =
  "Wenn aktiviert, rechnet das System Einheiten in das metrische System um, z.B. Esslöffel in Gramm oder Deziliter.";
export const ERROR_GIVE_FIELD_VALUE = (field: string) =>
  `Bitte ${field} angeben.`;
export const WE_NEED_THIS_VALUE = "Wir brauchen diesen Wert.";
export const GENERATED_ON = "Generiert am: ";
export const GENERATED_FROM = "Generiert von: ";
export const EXPORTED_ON = "Exportiert am: ";
export const EXPORTED_FROM = "Exportiert von: ";
export const ERROR_PARAMETER_NOT_PASSED =
  "Methodenparameter wurden nicht übergeben.";
export const SEARCH_STRING = "Suchbegriff";
export const DIALOG_TITLE_DELETION_CONFIRMATION = "Daten löschen?";
export const DIALOG_SUBTITLE_DELETION_CONFIRMATION =
  "Dadurch werden alle Daten endgültig gelöscht. Dieser Schritt kann nicht rückgängig gemacht werden.";
export const DIALOG_TEXT_DELETION_CONFIRMATION =
  "Bestätige, dass du diese Daten löschen möchtest. Gib dazu die folgende ID ein:";
export const DIALOG_SIGNOUT_USERS_CONFIRMATION = "Alle Users abmelden?";
export const DIALOG_SUBTITLE_SIGNOUT_USERS_CONFIRMATION =
  "Dadurch werden Benutzer*innen (ausser Admins) vom chuchipirat abgemeldet.";
export const DIALOG_TEXT_SIGNOUT_USERS_CONFIRMATION =
  "Bestätige, dass du alle abmelden möchtest. Gib dazu die folgende ID ein:";

export const REQUIRED = "Erforderlich";
export const DIALOG_DELETION_CONFIRMATION_STRING_DOES_NOT_MATCH =
  "Die angegebene ID ist nicht korrekt";
export const VERSION = "Version";
export const AUTH_SERVICE_HANLDER_NO_MODE =
  "Sorry. Wir wissen gerade nicht was zu tun ist. Bitte prüfe, ob du die richtige URL gewählt hast.";
export const LOG = "Log";
export const X_FEEDS_DELETED = (no_of_deleted_feeds: string) =>
  `${no_of_deleted_feeds} Feed-Einträge gelöscht.`;
export const ADVANCED_SEARCH = "Erweiterte Suche";
export const MENU_TYPE = "Menütyp";
export const MENU_TYPES = {
  1: "Hauptgang",
  2: "Beilage",
  3: "Vorspeise",
  4: "Dessert",
  5: "Frühstück",
  6: "Znüni/Zvieri",
  7: "Apero",
  8: "Getränk",
};
export const DIET_TYPES = {
  1: "Fleisch",
  2: "vegetarisch",
  3: "vegan",
};
// Wenn der Key fehlt, ist es...
export const ALLERGENS_FREE_TYPES = {
  1: "Laktosefrei",
  2: "Glutenfrei",
};
export const ALLERGEN_KEY_TEXT = {
  0: "-",
  1: LACTOSE,
  2: GLUTEN,
};
export const RENAME = "Umbenennen";
export const DELETE = "Löschen";
export const CANCEL = "Abbrechen";
export const CREATE = "Erstellen";
export const EDIT = "anpassen";
export const NAME = "Name";
export const CLOSE = "Schliessen";
export const APPLY = "Übernehmenen";
export const PER_PORTION = "pro Portion";
export const VARIANT = "Variante";
export const VARIANT_NOTE = "Varianten-Notiz";
export const SHOW_ONLY_NEWEST_PRODUCTS = "Zeige nur Neuste Produkte";
export const NO_NEWEST_PRODUCTS_FOUND =
  "Es wurden keine Produkte gefunden, die die letzten 10 Tage angelegt wurden.";
export const SHOW_ALL_PRODUCTS = "Zeige alle Produkte";
export const EXPLANATION_DIALOG_GOODS_TYPE_PRODUCT =
  "Hier kannst du einzelne Produkte dem Menü hinzufügen. Diese werden ebenfalls für die Einkaufsliste berücksichtigt.";
export const EXPLANATION_DIALOG_GOODS_TYPE_MATERIAL =
  "Hier kannst du einzelne Materialien dem Menü hinzufügen. Diese werden ebenfalls für die Einkaufs- resp. Materialliste berücksichtigt.";
export const EXPLANATION_DIALOG_GOODS_OPTION_TOTAL = (goodsType: string) =>
  `Mit der Option «Total» bestimmst du die Totalmenge, die zusätzlich von diesem ${goodsType} eingeplant wird. Eine Veränderung der Anzahl Portionen hat keinen Einfluss auf die von dir bestimmte Menge.`;
export const EXPLANATION_DIALOG_GOODS_OPTION_PER_PORTION =
  "Mit der Option «pro Portion» bestimmst du die Menge pro eingeplante Portion. Eine nachträgliche Veränderung der Anzahl Portionen beeinflusst auch die Menge der eingeplanten Menge. Die Bestimmung der Portionen geschieht im nächsten Schritt.";
export const QUANTITY = "Menge";
export const OK = "OK";
export const PRINTVERSION = "Druckversion";
export const ALL_MEAL_AND_VALUES_WILL_BE_DELETED =
  "Alle Menüs, Rezepte, Produkte und Materialien von dieser Mahlzeit werden gelöscht. Möchtest du fortfahren?";
export const ALL_RECIPES_AND_VALUES_WILL_BE_DELETED =
  "Alle Rezepte, Produkte und Materialien von diesem Menü werden gelöscht. Möchtest du fortfahren?";
export const DESCRIBE_YOUR_VARIANT =
  "Beschreibe, was deine Variante ausmacht. z.B. «laktosefrei», «ohne Erdnüsse» usw.";
export const UNSAVED_CHANGES = "Ungespeicherte Änderungen";
export const DISCARD_CHANGES = "Änderungen verwerfen";
export const RECALCULATE_PORTIONS = "Portionen neu berechnen";
export const PORTIONS_RECALCULATED =
  "Portionen im Menüplan neu berechnet und die neuen Einstellungen wurden gespeichert.";
export const RECIPE_WIHOUT_PORTIONPLAN =
  "Dieses Rezept besitzt keine Einplanung";
export const AND = "und";
export const OR = " oder";
export const SAVE = "Speichern";
export const OPEN = "Öffnen";
export const MENUE_SELECTION = "Auswahl Menüs";
export const USED_RECIPES_MENUE_SELECTION_DESCRIPTION =
  "Hier kannst du bereits generierte Listen anzeigen lassen oder eine neue Liste erstellen. Eine Liste beinhaltet alle verwendeten Rezepte der gewählten Menüs. Bei bestehenden Listen hast du die Möglichkeit, die Liste zu aktualisieren.";
export const SHOPPING_LIST_MENUE_SELECTION_DESCRIPTION =
  "Hier kannst du bereits generierte Einkaufslisten anzeigen lassen oder eine neue Liste erstellen. Eine Liste beinhaltet alle Zutaten, Gebrauchsmaterialien der gewählten Menüs (inklusive einzelne hinzugefügte Produkte). Bei bestehenden Listen hast du die Möglichkeit, die Liste zu aktualisieren.";
export const REFRESH = "Aktualisieren";
export const SELECT_MENUES = "Menüs auswählen";
export const WHICH_MENUES_FOR_RECIPE_GENERATION =
  "Für welche Menües sollen die Rezepte generiert werden?";
export const WHICH_MENUES_FOR_SHOPPING_LIST_GENERATION =
  "Für welche Menües soll die Einkaufsliste generiert werden?";
export const WHICH_MENUES_FOR_MATERIAL_LIST_GENERATION =
  "Für welche Menües soll die Materialliste generiert werden?";
export const SELECT_DAY = "Tag auswählen";
export const SELECT_ALL = "Alle auswählen";
export const EXISTING_LISTS = "Bestehende Listen";
export const LIST_ENTRY_MAYBE_OUT_OF_DATE = (listName: string) =>
  `Bitte beachte, dass der Menüplan in der Zwischenzeit geändert wurde. Dadurch könnten die Werte der angezeigten ${listName} möglicherweise nicht mehr korrekt sein. Bitte wähle Aktualisieren, um die Auswahl neu zu berechnen.`;
export const USED_RECIPES_OF_SHOPPINGLIST_POSSIBLE_OUT_OF_DATE =
  "Bitte beachte, dass der Menüplan in der Zwischenzeit geändert wurde. Dadurch könnten die angezeigten Mengen und Produkte möglicherweise nicht mehr korrekt sein. Bitte wähle Aktualisieren, um die Einkaufsliste neu zu generieren.";
export const PLANED_FOR = "Geplant für";
export const FOR_DATIVE = "zum";
export const UNIT = "Einheit";
export const UNITS = "Einheiten";
export const DIMENSION = "Dimension";
export const UNIT_ABREVIATION = "Einheit (Abkürzung)";
export const UID = "UID";
export const DENOMINATOR = "Menge von";
export const NUMERATOR = "Menge nach";
export const UNIT_FROM = "Einheit Von";
export const UNIT_TO = "Einheit Nach";
export const NEW_LIST = "Neue Liste";
export const GIVE_THE_NEW_LIST_A_NAME = "Gib dieser Liste einen Namen.";
export const GIVE_THE_NEW_SHOPPINGLIST_A_NAME =
  "Gib dieser Einkaufsliste einen Namen.";
export const CHANGE = "Ändern";
export const WHERE_DOES_THIS_ITEM_COME_FROM = (itemType: string) =>
  `Woher stammt dieses ${itemType}?`;
export const CREATE_NEW_LIST = "Neue Liste erstellen";
export const ADD_ITEM = "Artikel hinzufügen";
export const ITEM = "Artikel";
export const NEW_ITEM = "Neuer Artikel";
export const WHAT_KIND_OF_ITEM_ARE_YOU_CREATING =
  "Was für eine Art von Artikel möchtest du erfassen?";
export const FOOD = "Lebensmittel";
export const ITEM_CANT_BE_CHANGED = "Artikel kann nicht geändert werden.";
export const WHERE_IS_THIS_PRODUCT_USE = "Wo wird dieses Produkt verwendet?";
export const THE_QUANTITY_HAS_BEEN_MANUALY_EDITED =
  "Die automatische berechnete Menge wurde manuell angepasst.";
export const ADDED_MANUALY = "manuell hinzugefügt";
export const ARTICLE_ALREADY_ADDED = "Artikel bereits vorhanden";
export const ADD_OR_REPLACE_ARTICLE = (
  article: string,
  unit: string,
  oldQuantity: string,
  newQuantity: string
) =>
  `Das Produkt «${article}» ist in der Einheit «${unit}» in der Einkaufsliste bereits vorhanden. Wie soll mit der hinzufügenden Menge fortgefahren werden? Möchtest du die bestehende Menge von ${oldQuantity} ${unit} mit der neuen Menge von ${newQuantity} ${unit} überschreiben oder dazuzählen?`;
export const REPLACE = "Ersetzen";
export const SUM = "Dazuzählen";
export const MANUALLY_ADDED_PRODUCTS = "Manuell hinzugefügte Artikel";
export const KEEP_MANUALLY_ADDED_PRODUCTS = (listName: string) =>
  `Diese ${listName} beinhaltet manuell hinzugefügte Artikel. Sollen diese bei der Aktualisierung beibehalten oder sollen sie aus der Liste gelöscht werden?`;
export const KEEP = "Behalten";
export const MATERIAL_LIST_MENUE_SELECTION_DESCRIPTION =
  " Hier kannst du bereits generierte Materiallisten anzeigen lassen oder eine neue Liste erstellen. Eine Liste beinhaltet alle Materialien vom Typ «Gebrauchsmaterial» der gewählten Menüs. Bei bestehenden Listen hast du die Möglichkeit, die Liste zu aktualisieren.";
export const LIST = "Liste";
export const ATTENTION_ABOUT_TO_DELETE_PLANED_DAYS =
  "Achtung – Geplante Tage werden gelöscht!";
export const DELETION_AFFECTS_PLANED_DAYS =
  "Durch die vorgenommene Änderung hast du Tage entfernt, die bereits in der Planung berücksichtigt sind. Wenn du auf «Fortfahren» klickst, werden diese Tage zusammen mit den bereits eingeplanten Rezepten gelöscht.";
export const PROCEED = "Fortfahren";
export const MATERIALS = "Materialien";
export const PRIVACY_POLICY = "Datenschutzerklärung";
export const TERM_OF_USE = "Nutzungsbedingungen";
export const SMALL_PRINT = "das Kleingedruckte";
export const EVENT = "Anlass";
export const EVENTS = "Anlässe";
export const USERS = "Users";
export const MEMBER_ID = "Member-ID";
export const MEMBER_SINCE = "Mit dabei seit";
export const ROLES = "Rolen";
export const PUBLIC = "Öffentlich";
export const PRIVATE = "Privat";
export const PUBLIC_RECIPE = "Öffentliches Rezept";
export const ARE_YOU_SURE_YOU_WANT_TO_CHANGE =
  "Bist du sicher, dass du dieses Objekt ändern willst?";
export const EDIT_AUTHORIZATION = "Berechtigung bearbeiten";
export const EDIT_AUTHORIZATION_DESCRIPTION =
  "Alle darunterliegenden Berechtigungen werden automatisch mit vergeben.";
export const RE_SIGN_IN_REQUIRED = "Neu-Anmeldung nötig";
export const RE_SIGN_IN_REQUIRED_AFTER_ROLES_ASSIGNMENT =
  "Die vergebenen Rechte sind erst nach einer erneuten Anmeldung aktiv.";
export const THIS_RECIPE_IS_PLANNED_FOR = "Dieses Rezept ist geplant für:";
export const EVENT_UID = "Event-UID";
export const ACTIVATE_SUPPORT_MODE = "Support-Modus aktivieren";
export const ACTIVATE_SUPPORT_MODE_DESCRIPTION =
  "Um den Support-Modus zu aktivieren, kannst du die UID eines Anlasses hier eingeben. Danach wird der Support-User für den angegebenen Anlass bis 01:00 Uhr Morgen früh berechtigt.";
export const SUPPORT_USER_REGISTERED =
  "Support-User wurde für Anlass berechtigt.";
export const MAILBOX = "Mailbox";
export const MONITOR = "Monitor";
export const OVERVIEW = "Übersicht";
export const RECIPIENT_TO = "An";
export const RECIPIENT_BCC = "BCC";
export const MAIL_DATA = "E-Mail-Inhalt";
export const DELETE_MAIL_PROTOCOLS = "Mailprotokolle löschen";
export const DELETE_MAIL_PROTOCOLS_OLDER_THAN =
  "Mailprotokolle löschen, die älter als X Tage sind";
export const MAIL_PROTOCOLS = "Mailprotokolle";
export const MAIL_PROTOCOLS_DELETED = "Mailprotokolle gelöscht";
export const CLOUD_FX = "Cloud-Functions";
export const DELETE_CLOUD_FX_TRIGGER_DOCS = "Cloud-FX Dokumente löschen";
export const DELETE_CLOUD_FX_TRIGGER_DOCS_OLDER_THAN =
  "Cloud-FX Dokumente löschen, die älter als X Tage sind";
export const CLOUD_FX_TRIGGER_DOCS = "Cloud-FX Triggerdokumente";
export const CLOUD_FX_TRIGGER_DOCS_DELETED = "Mailprotokolle gelöscht";
export const DATE = "Datum";
export const INVOKED = "Ausgeführt";
export const PROCESSED_DOCUMENTS = "Verarbeitete Dokumente";
export const UNIT_DIMENSION = {
  VOL: "Volumen",
  MAS: "Masse",
  DLS: "Dimensionslos",
};
export const FEEDS = "Feed-Einträge";
export const RECEIPT = "Quittung";
export const CREATE_RECEIPT = "Quittung erstellen";
export const PAY_DATE = "Bezahlt am";
export const DONOR = "Spender*in";
export const AMOUNT = "Betrag";
export const RECEIPT_THANK_YOU =
  "Danke für deine Spende. Deine Spende ermöglicht es, den chuchipirat weiterzuentwickeln und weiterhin kostenlos anzubieten.";
export const ASSOCIATION = "Verein";
export const TYPE = "Typ";
export const INVOLVED_EVENTS = "Beteiligte Anlässe lesen";
export const VALID_TO = "Gültig bis";
export const HOME_DASHBOARD = "Home-Dashboard";
/* =====================================================================
// Abkürzungen
// ===================================================================== */
export const ABBREVIATION_UNIT = "Einh.";
/* =====================================================================
// Fehler
// ===================================================================== */
export const ERROR_WRONG_DB_CLASS =
  "Falsche DB Klasse angesprochen. Prüfe die Klassen mit Vererbung. ";
export const ERROR_NOT_IMPLEMENTED_YET = "☠️ Not implemented yet! ☠️";
export const ERROR_NO_RECIPES_FOUND = "Die Auswahl beinhaltet keine Rezepte.";
export const ERROR_NO_MATERIALS_FOUND =
  "Die Auwahl beinhaltet keine Materialien.";
export const ERROR_NO_PRODUCTS_FOUND = "Die Auwahl beinhaltet keine Produkte.";
export const ERROR_NO_RECIPE_PRODUCT_MATERIAL_FOUND =
  "Die Auswahl beinhaltet keine Artikel.";
/* =====================================================================
// Workflow
// ===================================================================== */
export const STATUS_NAME = {
  created: "Neu",
  inReview: "wird geprüft",
  backToAuthor: "zurück zu Autor*in",
  declined: "Abgelehnt",
  done: "Erledigt",
};
export const REQUEST_STATUS_TRANSITION_PUBLISH_RECIPE = {
  created: {
    inReview: {description: "Antrag prüfen"},
    declined: {description: "Antrag ablehnen"},
    backToAuthor: {description: "Zurück zu Author*in"},
    done: {description: "Rezept publizieren"},
  },
};
export const REQUEST_STATUS_TRANSITION_REPORT_ERROR = {
  created: {
    inReview: {description: "Meldung prüfen"},
    declined: {description: "Meldung ablehnen"},
    backToAuthor: {description: "Zurück zu Author*in"},
    done: {description: "Rezept wurde korrigiert"},
  },
};
/* =====================================================================
// Request
// ===================================================================== */
export const REQUEST = "Antrag";
export const REQUEST_TYPE = {
  recipePublish: "Rezeptveröffentlichung",
  reportError: "Fehler im Rezept",
};
export const REQUEST_TYPE_LABEL = "Typ";
export const REQUEST_OBJECT_LABEL = "Name Objekt";
export const REQUEST_STATUS = "Status";
export const REQUEST_CREATION_DATE = "Erstellungsdatum";
export const REQUEST_AUTHOR_DISPLAYNAME = "Autor*in";
export const REQUEST_ASSIGNEE_DISPLAYNAME = "Bearbeiter*in";
export const REQUEST_NEXT_POSSIBLE_TRANSITION_LABEL =
  "Nächster möglicher Schritt";
export const REQUEST_NEXT_POSSIBLES_TRANSITIONS_LABEL =
  "Nächste mögliche Schitte";
export const REQUEST_ASSIGN_TO_ME_LABEL = "Antrag mir zuweisen";
export const NO_OPEN_REQUESTS_FOUND = "Keine offenen Anträge gefunden.";
export const ACTIVE_REQUESTS = "Aktive Anträge";
export const ALL_REQUESTS = "Alle Anträge";
export const WRONG_ASIGNEE = "Der Antrag ist nicht dir zugewiesen.";
